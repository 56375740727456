import PortfolioIcon from 'shared/assets/icons/Icon-portfolio.webp'
import ProtectIcon from 'shared/assets/icons/Icon-protect.webp'
import TargetIcon from 'shared/assets/icons/Icon-target.webp'
import MouseIcon from 'shared/assets/icons/Icon-mouse.webp'

type CardsContentType = {
  id: number
  icon: string
  title: string
  desc1: string
  desc2?: string
}

export const cardsContent: CardsContentType[] = [
  {
    id: 1,
    icon: PortfolioIcon,
    title: 'Быстрое открытие инвест-счета',
    desc1:
      'Freedom не взимает комиссий за покупку и продажу активов. Открытие инвестиционного счета для новых инвесторов происходит в течение двух часов.',
  },
  {
    id: 2,
    icon: ProtectIcon,
    title: 'Полное возмещение депозита',
    desc1:
      'Инвестируя Вы гарантировано сохраняете вложенную Вами сумму (Закон Республики Молдова от 7 июля 2006 года № 169-III)',
    },
  {
    id: 3,
    icon: TargetIcon,
    title: 'Трейдинг с помощью торгового робота',
    desc1:
      'Технология FREEDOM.AI анализирует сделки и динамику курсов ценных бумаг  квантовым компьютером. Так мы получаем 73,9% успешных сделок',
    },
  {
    id: 4,
    icon: MouseIcon,
    title: 'Защита от падения курса лей',
    desc1:
      'Начать инвестировать можно всего с 250$. Все активы индексируются долларом, и в случае падения лей Вы ничего не потеряете.',
    },
]
